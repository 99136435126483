import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import { aboutData } from "../constants/about"
import { Grid, Typography, Box, List, Stack, Button } from "@mui/material"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import LaptopIcon from "@mui/icons-material/Laptop"
import ContactPageIcon from "@mui/icons-material/ContactPage"

const About = () => {
  const { intro1, intro2, email, skills, software } = aboutData
  const { photo1, photo2 } = useStaticQuery(query)

  return (
    <Layout>
      <Seo
        title="About"
        description="Website and detailed online portfolio of my work."
      />
      <Box
        component="main"
        sx={{
          minHeight: "100%",
          p: {
            xs: "3rem 2rem",
            sm: "3rem 4rem",
          },
        }}
      >
        <Typography variant="h1" align="center" gutterBottom>
          About me
        </Typography>
        <Grid container spacing={4} sx={{ alignItems: "center", mb: 2 }}>
          <Grid item xs={12} sm={4}>
            <GatsbyImage
              image={photo1.childImageSharp.gatsbyImageData}
              className="hero-img"
              alt="Ha Truong"
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {intro1}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {intro2}
            </Typography>
            <Stack
              direction="row"
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 1,
              }}
            >
              <Link to="/projects">
                <Button variant="outlined" startIcon={<LaptopIcon />}>
                  Projects
                </Button>
              </Link>
              <a href={`mailto:${email}`}>
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<ContactPageIcon />}
                >
                  Contact
                </Button>
              </a>
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ alignItems: "center" }}>
          <Grid item xs={0} sm={8}>
            <Box
              sx={{
                display: "grid",
              }}
            >
              <Typography variant="h4" component="h3">
                My skills
              </Typography>
              <List style={{ listStyleType: "disc", marginLeft: "1.5rem" }}>
                {skills.map((skill, index) => {
                  return (
                    <li>
                      <Typography variant="body1" gutterBottom>
                        {skill}
                      </Typography>
                    </li>
                  )
                })}
              </List>
              <div className="btn-stack">
                {software.map((item, index) => {
                  return <span key={index}>{item}</span>
                })}
              </div>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            <GatsbyImage
              image={photo2.childImageSharp.gatsbyImageData}
              className="hero-img"
              alt="skills"
            />
          </Grid>
        </Grid>
      </Box>
    </Layout>
  )
}

const query = graphql`
  {
    photo1: file(relativePath: { eq: "hero-img.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    photo2: file(relativePath: { eq: "laptop.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`

export default About
