export const aboutData = {
  intro1:
    "I have over 5 years of experience in digital marketing, with extensive knowledge of SEO. Also, I'm a self-taught web developer focused on front-end UI/UX.",
  intro2:
    "I've dedicated my time to creating websites, managing content, increasing organic visibility online, and ultimately driving more sales conversions.",
  email: "hello@hatruong.dev",
  skills: [
    "Frontend web development (React, JavaScript)",
    "Digital marketing (SEO, SEM, Content Marketing, Social Marketing)",
    "Marketing strategy (B2B/ B2C)",
    "Data Analysis",
    "Project Management",
  ],
  software: [
    "HTML",
    "CSS",
    "JavaScript",
    "Typescript",
    "React",
    "Gatsby",
    "Python",
    "SQL",
    "GraphQL",
    "Wordpress",
    "Material UI",
  ],
}
